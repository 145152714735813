/* ==================== Landing Page Style ==================== */
.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  background-color: #ae2129;
  text-align: center;
}

.App-logo {
  height: 12em;
  pointer-events: none;
  animation: fade-in ease 3s;
  transition: 0.03s ease-in-out;
}

.body {
  width: 100%;
  align-items: center;
}

/* Navbar */
.navbar-container {
  width: 100%;
  height: 6em;
  padding: 2em;
  background-color: #ae2129;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.navbar-container-mini {
  width: 100%;
  height: 4em;
  padding: 2em;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.navbar-container-mini .App-logo {
  height: 3em;
}

.navbar-container .App-logo {
  height: 4em;
}

/* == Mobile First == */

.landing-base-container {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background-color: #ae2129;
  /* background: ivory; */
}

.dot-background {
  background-color: #ae2129;
  opacity: 0.8;
  background-image: radial-gradient(#fafafc 0.5px, #ae2129 0.5px);
  background-size: 10px 10px;
}

/* Mobile: 480;
Tablet: 768;
desktop: 1279; */
@media screen and (max-width: 480px) {
  .container-landing-page {
    width: 100vw;
    min-height: 100vh;
    /* background-color: green; */
  }

  .container-tagline {
    width: 100%;
    height: 25vh;
    background-color: rgb(235, 224, 183);
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .container-tagline .title-container {
    width: 50%;
    height: 25vh;
    padding: 1em;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .container-tagline .tagline-img {
    width: 50%;
    height: 100%;
    overflow: hidden;
    object-fit: cover;
  }
}

/* ==================== Loading Page Style ==================== */

.tagline {
  animation: fade-in ease 3s;
  animation-delay: 1s;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/* ==================== 404 Style ==================== */
.container-not-found {
  height: 100vh;
  background-color: #ae2129;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.title-not-found {
  font-size: 4em;
  font-weight: bold;
  margin-bottom: 4;
  color: #ffccaa;
}

.subtitle-not-found {
  font-size: 2em;
  font-weight: 800;
  margin-bottom: 0.6em;
  color: #ffccaa;
}

.desc-not-found {
  font-size: 0.8em;
  font-weight: 800;
  margin-bottom: 2em;
  color: #ffccaa;
}

.button-not-found {
  padding: 6px 8px 6px 8px;
  background-color: #ae2129;
  font-size: 1em;
  font-weight: 800;
  color: #ffccaa;
  text-decoration: none;
}

.button-not-found:hover {
  padding: 6px 8px 6px 8px;
  border-radius: 4px;
  font-size: 1em;
  font-weight: 800;
  background-color: #561a1e;
  color: #ffccaa;
  text-decoration: none;
  transition: ease-in-out;
  transition-duration: 300;
}

::-webkit-scrollbar {
  width: 0px;
  background: transparent; /* make scrollbar transparent */
}
